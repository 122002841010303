<template>
	<section class="video-container">
		<!-- HTML5 video source - desktop and/or mobile -->
		<TnVideoHtml
			v-if="srcHtml && mounted"
			:src="srcHtml"
			ref="html"
			v-bind="$attrs"
			:autoplay="autoplay"
		/>

		<!-- YouTube embedded iframe API source -->
		<TnVideoYoutube
			v-if="srcYoutube && !srcHtml && mounted"
			:src="srcYoutube"
			ref="youtube"
			v-bind="$attrs"
			:autoplay="autoplay"
		/>
	</section>
</template>

<script>
/**
 * TnVideo is a component for easy playback of video in the browser, either as an embedded YouTube video, or using the
 * HTML5 native video-element.
 *
 * The TnVideo component is a _wrapper_ component that will use one of its subcomponents - TnVideoYoutube or TnVideoHtml
 * - depending on the source provided.
 *
 * @displayName TnVideo
 */
export default defineComponent({
	name: "TnVideo",
	inheritAttrs: false,

	props: {
		/**
		 * Source for the video to embed - either full URL or YouTube unique video ID. If set, will take precedence over
		 * HTML5 source.
		 */
		srcYoutube: {
			type: String,
			default: "",
		},
		/**
		 * Source for HTML5 compatible video (mp4, webm).
		 */
		srcDesktop: {
			type: String,
			default: "",
		},
		/**
		 * Source for HTML5 compatible video (mp4, webm) for mobile.
		 */
		srcMobile: {
			type: String,
			default: "",
		},
		/**
		 * The breakpoint (pixels) where to switch between desktop and mobile source if both present.
		 */
		breakpointMobile: {
			type: [Number, String],
			default: 767,
		},
		/**
		 * Autoplay the video when ready.
		 */
		autoplay: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			isMobile: false,
			mounted: false,
		};
	},

	computed: {
		srcHtml() {
			if (this.isMobile && this.srcMobile) {
				return this.srcMobile;
			}
			return this.srcDesktop;
		},
	},

	mounted() {
		this.mounted = true;
		this.isMobile = window.innerWidth <= 768;
		if (this.srcMobile && (this.srcDesktop || this.srcYoutube)) {
			this.$nextTick(function () {
				window.addEventListener("resize", this.handleWindowResize);
				this.handleWindowResize();
			});
		}
	},

	beforeUnmount() {
		window.removeEventListener("resize", this.handleWindowResize);
	},

	methods: {
		handleWindowResize() {
			this.isMobile = document.documentElement.clientWidth <= this.breakpointMobile;
		},
	},
});
</script>

<style lang="scss" scoped>
.video-container {
	display: inline-block;
	width: 100%;
	height: auto;
}
</style>
