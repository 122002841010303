export default {
	src: {
		type: String,
	},
	autoplay: {
		type: Boolean,
		default: false,
	},
	muted: {
		type: Boolean,
		default: false,
	},
	loop: {
		type: Boolean,
		default: false,
	},
	controls: {
		type: Boolean,
		default: false,
	},
	poster: {
		type: String,
	},
	darkIcons: {
		type: Boolean,
		default: false,
	},
};
